import React, { createContext, useReducer, useEffect } from "react";

//* ==> Reducers <== *//
import sessionReducer, { initialState, STORAGE_KEY } from "@reducers/sessionReducer";

// Hook
import SecurityStorage from "@/hooks/SecurityStorage";

export const SessionContext = createContext({ state: initialState, dispatch: () => { } })

/**
 * Provider for the session context.
 *
 * @param {Object} props - The props for the provider.
 * @param {Object} props.children - The children components to be rendered within the provider.
 * @returns {JSX.Element} The session context provider component.
 */
export const SessionProvider = ({ children }) => {
    const [state, dispatch] = useReducer(sessionReducer, initialState);

    useEffect(() => {
        restoreState();
    }, []);

    // window.addEventListener("storage", (ev) => {
    //     if (ev.key === STORAGE_KEY) {
    //         restoreState();
    //     }
    // });

    const restoreState = () => {
        // ==> Validar si existe una session en el localStorage
        const session = SecurityStorage.getItem(STORAGE_KEY);
        if (session) {
            // Comparar session con state
            if (state && JSON.stringify(state) === session) return;

            // ==> Si existe una session, la cargamos en el state
            const body = JSON.parse(session) ?? {};
            dispatch({ type: "RESTORE", payload: body });
        }
    }

    return (
        <SessionContext.Provider value={{ state, dispatch }}>
            {children}
        </SessionContext.Provider>
    );
};
