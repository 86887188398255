import React from "react";

//* ==> Components <== *//
import Loading from "@components/Loading";

// Hooks
import useLogout from "@hooks/useLogout";

const Logout = () => {

  const { stateCerrarSession: stateLogout } = useLogout();


  return <>{stateLogout.isLoading && <Loading />}</>;
};

export default Logout;
