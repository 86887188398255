import CryptoJS from 'crypto-js';

// ==> Env
import { PASSWORD, SUSTKEY1 } from "@config/env";

/**
 * Class for handling secure storage.
 */
class SecurityStorage {

    /**
    * Encrypts the given data.
    *
    * @param {Object} data - The data to be encrypted.
    * @returns {string} The encrypted data.
    */
    static encrypt(data) {
        if (!data) return null;
        try {
            const encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), PASSWORD).toString();
            const SUST = SUSTKEY1.split("-");

            let encryptSust = "";
            // Colocar SUST 1 al inicio
            encryptSust = SUST[0] + encrypt;

            // Colocar SUST 2 al centro
            const pos = encryptSust.length / 2;
            encryptSust = encryptSust.substring(0, pos) + SUST[1] + encryptSust.substring(pos);

            // Colocar SUST 3 al final
            encryptSust = encryptSust + SUST[2];

            return encryptSust;
        } catch (err) {
            return data ?? null;
        }
    }

    /**
     * Decrypts the given data.
     *
     * @param {string} data - The data to be decrypted.
     * @returns {Object} The decrypted data.
     */
    static decrypt(data) {
        if (!data) return null;
        try {
            const SUST = SUSTKEY1.split("-");

            // Quitar SUST 3 al final
            let decryptSust = data?.includes(SUST[2]) ? data?.replace(SUST[2], "") : data;

            // Quitar SUST 2 al centro
            decryptSust = decryptSust?.includes(SUST[1]) ? decryptSust?.replace(SUST[1], "") : decryptSust;

            // Quitar SUST 1 al inicio
            decryptSust = decryptSust?.includes(SUST[0]) ? decryptSust?.replace(SUST[0], "") : decryptSust;

            const bytes = CryptoJS.AES.decrypt(decryptSust, PASSWORD);
            const decrypt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return decrypt;
        } catch (err) {
            return data ?? null;
        }
    }

    /**
     * Sets an item in the secure storage.
     *
     * @param {string} key - The key under which to store the item.
     * @param {Object} value - The item to be stored.
     * @param {boolean} encrypt - Whether to encrypt the item.
     */
    static setItem(key, value, encrypt = true) {
        const data = encrypt ? SecurityStorage.encrypt(value) : value;
        localStorage.setItem(key, data);
    }

    /**
     * Retrieves an item from the secure storage.
     *
     * @param {string} key - The key under which the item is stored.
     * @returns {Object} The retrieved item.
     */
    static getItem(key) {
        const encrypt = localStorage.getItem(key);
        const decrypt = SecurityStorage.decrypt(encrypt);
        return decrypt;
    }

    /**
     * Removes an item from the secure storage.
     *
     * @param {string} key - The key under which the item is stored.
     */
    static removeItem(key) {
        localStorage.removeItem(key);
    }

    /**
     * Clears the secure storage.
     */
    static clear() {
        localStorage.clear();
    }

}

export default SecurityStorage;