import i18next from "i18next";
import { useHistory } from "react-router-dom";

//* ==> UseDataApi <== *//
import useDataApi from "@hooks/useDataApi";

//* ==> Paths <== *//
import { pathCerrarSession } from "@config/paths";

//* ==> Context <== *//
import useSessionContext from "@contexts/useSessionContext"

// Helper
import { alertNotification } from "@/helpers/notifications";

const useLogout = () => {

    const history = useHistory();

    const { state: store, dispatch } = useSessionContext();

    const {
        session: {
            id: idSssionStore,
            rToken: rTokenStore,
            token: tokenStore,
        },
        user: {
            code: codUserStore
        },
    } = store;

    const [stateCerrarSession, fetchCerrarSession] = useDataApi({
        url: pathCerrarSession,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        isLogout: true,
    });

    const logout = async (manaul = false) => {

        if (!(
            tokenStore && tokenStore !== "" &&
            codUserStore && codUserStore !== "" &&
            idSssionStore && idSssionStore !== "" &&
            rTokenStore && rTokenStore !== ""
        )) {
            clearStorage(manaul);
            return;
        }

        await fetchCerrarSession({
            method: "post",
            fetchHeaders: {
                accessToken: tokenStore ?? "",
            },
            body: {
                codCanal: "BXI",
                codServicio: "BXI.CIERRE.SESION",
                codUsuario: codUserStore ?? "",
                idSesion: idSssionStore ?? "",
                refreshToken: rTokenStore ?? "",
            },
        });

        clearStorage(manaul);
    };

    const clearStorage = (manaul = false) => {
        // ==> Mostrar Mensaje
        const title = i18next.t("sesionCerrada-label");
        const msg = manaul ? i18next.t("cerrarSession-msg") : i18next.t("forceLogout-msg");
        const type = manaul ? "success" : "warning";
        if (codUserStore && tokenStore) alertNotification(title, msg, type);

        // ==> Limpiar Context
        dispatch({ type: "KILL_SESION", payload: { msg } });

        try {
            history.push("/");
        } catch (err) {
            const preFix = window.location.hostname === "localhost" ? "" : "/banca-bfa";
            window.location.href = `${preFix}/`;
        }
    };

    return { stateCerrarSession, logout };
}

export default useLogout;