const maskNEP = "9999-9999-9999-9999-9999-9999-9999";

const maskDUI = "99999999-9";

const maskNIT = "9999-999999-999-9";

const maskTelefono = "9999-9999";

const maskNumPrestamo = "999-999-999999-9";

const maskCuenta = "999-999-999999-9";

const maskNUP = "999999999999";

export {
  maskNEP,
  maskDUI,
  maskNIT,
  maskNumPrestamo,
 maskTelefono,
  maskCuenta,
  maskNUP,
};
