import { useReducer, useContext } from "react";
import axios from "axios";
import moment from "moment";

//* ==> Reducers 
import apiReducer, { initialState } from "@reducers/apiReducer";

//* ==> Provider
import { CacheContext } from "@providers/CacheProvider";

//* ==> Context
import useSessionContext from "@contexts/useSessionContext";

/**
 * Estado de la solicitud
 * @typedef {Object} state
 * @prop {boolean} isLoading - Cargando
 * @prop {boolean} isError - Ocurrio un error
 * @prop {boolean} isSuccess - La solicitud ha sido completada con exito
 * @prop {boolean} status - Estado de la peticion
 * @prop {any} data - Respuesta de la solicitud
 * @prop {any} error - Error de la solicitud
 */

/**
 * Configuracion inicial
 * @typedef {Object} initialSettings
 * @prop {string} [url] - Url inicial de la api a solicitar
 * @prop {any} [headers] - Header de la peticion
 * @prop {boolean} [hasCache] - Cache los resultados en context
 */

/**
 * useDataApi
 * @param {initialSettings} [initialSettings] - Configuracion inicial
 * @returns {[state, fetchData]} Estado del hook y funcion fetchData
 */
const useDataApi = ({
  url: originalUrl,
  headers = null,
  hasCache = false,
  isLogout = false,
} = {}) => {
  const [state, dispatch] = useReducer(apiReducer, initialState);

  const { state: stateCache, dispatch: dispatchCache } = useContext(CacheContext);

  const { state: stateSession } = useSessionContext();

  const requestApi = async (request, stringRequest) => {
    try {
      dispatch({ type: "FETCH_INIT" });
      const result = await axios(request);
      const { data, status } = result;

      dispatch({ type: "FETCH_SUCCESS", payload: { data, status } });

      if (hasCache) {
        dispatchCache({
          type: "SET_CACHE",
          payload: { key: stringRequest, data },
        });
      }

      return { ok: true, data };
    } catch (error) {
      console.error("|| ==> Error requestApi UseDataApi <== ||", error);
      dispatch({
        type: "FETCH_FAILURE",
        payload: { data: error, status: error },
      });

      return { ok: false, data: error };
    }
  };

  /**
   * Parametros
   * @typedef {Object} fetchDataParams
   * @prop {any} [body] - Cuerpo de la peticion
   * @prop {string} [method=get] - tipo de metodo
   * @prop {string} [params] - parametros get de la peticion
   * @prop {string} [url] - url de la peticion
   * @prop {boolean} [refreshCache=false] - Refresca el cache si existe
   * @prop {any} [fetchHeaders={}] - Refresca el cache si existe
   * @prop {any} [concatUrl=''] - Refresca el cache si existe
   */

  /**
   * fetchData
   * @param {fetchDataParams}
   */
  const fetchData = async ({
    body = "",
    method = "get",
    params = undefined,
    url = null,
    refreshCache = false,
    fetchHeaders = null,
    concatUrl = "",
  } = {}) => {
    if (state.isLoading) return; // hay una solicitud en proceso

    // Validacion sesion activa
    const { isLogged, session: { logoutTime } } = stateSession;

    if (isLogged && moment().isAfter(moment(logoutTime))) {
      if (!isLogout) return;
    };

    let newHeaders = {};
    fetchHeaders ? (newHeaders = fetchHeaders) : (newHeaders = headers);
    const request = {
      method,
      url: (url || originalUrl) + concatUrl,
      headers: newHeaders,
      data: body,
      params,
    };

    if (!hasCache) {
      return requestApi(request);
    }

    const stringRequest = JSON.stringify(request);

    if (!refreshCache && stateCache[stringRequest]) {
      dispatch({ type: "FETCH_SUCCESS", payload: { data: stateCache[stringRequest], status: 200 } });
      return { ok: true, data: stateCache[stringRequest] };
    } else {
      return requestApi(request, stringRequest);
    }
  };

  return [state, fetchData];
};

export default useDataApi;
