import React, { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import axios from "axios";

import { setGlobals } from "@anb98/react-hooks";

// ==> Contexts
import useSessionContext from "@contexts/useSessionContext";

// ==> Components
import PrivateRouter from "@components/PrivateRoutes";
import Loading from "@components/Loading";
import LogoutHook from "@pages/Session/logout";
import ExtendSession from "@hooks/ExtendSession";

// * ==> Hooks
import useLogout from "@hooks/useLogout";

// ==> Pages components
const Login = lazy(() => import("@pages/Login"));
const AprobacionesPendientes = lazy(() =>
  import("@pages/AprobacionesPendientes")
);

// Cuentas Corriente
const ConsultaCuentaCorriente = lazy(() =>
  import("@pages/cuentasCorriente/ConsultaCuentaCorriente")
);
const DetalleCuentaCorriente = lazy(() =>
  import("@pages/cuentasCorriente/DetalleCuentaCorriente")
);
const MovimientosCuentaCorriente = lazy(() =>
  import("@pages/cuentasCorriente/MovimientosCuentaCorriente")
);
const EstadoCuentaCorriente = lazy(() =>
  import("@pages/cuentasCorriente/EstadoCuentaCorriente")
);

// Cuentas Ahorro
const ConsultaCuentasAhorro = lazy(() =>
  import("@pages/CuentasAhorro/ConsultaCuentasAhorro")
);
const DetalleCuentaAhorro = lazy(() =>
  import("@pages/CuentasAhorro/DetalleCuentaAhorro")
);
const MovimientosCuentaAhorro = lazy(() =>
  import("@pages/CuentasAhorro/MovimientosCuentaAhorro")
);
const EstadoCuentaAhorro = lazy(() =>
  import("@pages/CuentasAhorro/EstadoCuentaAhorro")
);

// Depositos a plazo
const ConsultaDepositosPlazo = lazy(() =>
  import("@pages/DepositosPlazo/ConsultaDepositosPlazo")
);
const DetalleDepositosPlazo = lazy(() =>
  import("@pages/DepositosPlazo/DetalleDepositosPlazo")
);

const SolicitudChequera = lazy(() => import("@pages/SolicitudChequera"));
const PreSolicitudCredito = lazy(() => import("@pages/PreSolicitudCredito"));
const PreSolicitudSeguro = lazy(() => import("@pages/PreSolicitudSeguro"));
const PreSolicitudSuspensionCheques = lazy(() =>
  import("@pages/PreSolicitudSuspensionCheques")
);
const PreSolicitudCaptacion = lazy(() =>
  import("@pages/PreSolicitudCaptacion")
);
const PagoPrestamoPropio = lazy(() =>
  import("@pages/Prestamos/PagoPrestamoPropio")
);

// Prestamos
const ConsultasSegurosDePrestamos = lazy(() =>
  import("@pages/ConsultasSegurosDePrestamos")
);
const PagoPrestamosTerceros = lazy(() =>
  import("@pages/Prestamos/PagoPrestamosTerceros")
);
const ConsultaPrestamos = lazy(() =>
  import("@pages/Prestamos/ConsultaPrestamos")
);
const DetallePrestamo = lazy(() => import("@pages/Prestamos/DetallePrestamo"));

const SolicitudTransferenciasInternacionales = lazy(() =>
  import(
    "@pages/TransferInternacionales/SolicitudTransferenciasInternacionales"
  )
);
const SolicitudChequesInternacionales = lazy(() =>
  import("@pages/SolicitudChequesInternacionales")
);
const SolicitudChequeGerencia = lazy(() =>
  import("@pages/SolicitudChequeGerencia")
);
const TransferenciaCtaPropias = lazy(() =>
  import("@pages/TransferenciasCtas/transferenciaCtaPropias")
);
const TransferenciaCtaTerceros = lazy(() =>
  import("@pages/TransferenciasCtas/transferenciaCtaTerceros")
);
const ReporteTarjetaDebitoViaje = lazy(() =>
  import("@pages/ReporteTarjetaDebitoViaje")
);

// Planilla
const CargaPlanilla = lazy(() => import("@pages/Planillas/CargaPlanilla"));

// Colectores
const MinisterioHacienda = lazy(() =>
  import("@pages/Colectores/MinisterioHacienda")
);
const DELSUR = lazy(() => import("@pages/Colectores/DelSur"));
const Isss = lazy(() => import("@pages/Colectores/Isss"));
const Claro = lazy(() => import("@pages/Colectores/Claro"));
const GrupoAES = lazy(() => import("@pages/Colectores/GrupoAES"));
const CNR = lazy(() => import("@pages/Colectores/CNR"));
const CIEX = lazy(() => import("@pages/Colectores/CIEX"));
const FSV = lazy(() => import("@pages/Colectores/FSV"));
const AfpConfia = lazy(() => import("@pages/Colectores/AFP-confia"));
const AfpCrecer = lazy(() => import("@pages/Colectores/AFP-crecer"));
// ==> Punto Express
const PuntoExpress = lazy(() =>
  import("@pages/Colectores/PuntoExpress/PuntoExpress")
);

// Administración
// ==> Favoritos
const Favoritos = lazy(() =>
  import("@pages/administracion/Favoritos/favoritos")
);
const DetalleFavoritos = lazy(() =>
  import("@pages/administracion/Favoritos/detalleFavorito")
);

// ==> Cargos Automaticos
const CargosAutomaticos = lazy(() =>
  import("@pages/administracion/SolicitudCargosAutomaticos/cargosAutomaticos")
);
const DetalleCargosAutomaticos = lazy(() =>
  import(
    "@pages/administracion/SolicitudCargosAutomaticos/detalleCargosAutomaticos"
  )
);

// ==> Administración de Usuarios
const UsuariosAnexos = lazy(() =>
  import("@pages/administracion/UsuariosAnexos/usuariosAnexos")
);
const DetalleUsuariosAnexos = lazy(() =>
  import("@pages/administracion/UsuariosAnexos/detalleUsuarioAnexos")
);
const BitacoraUsuarioAnexo = lazy(() =>
  import("@pages/administracion/UsuariosAnexos/Bitacora/bitacoraUsuarioAnexo")
);
const DetalleBitacoraUsuarioAnexo = lazy(() =>
  import(
    "@pages/administracion/UsuariosAnexos/Bitacora/detalleBitacoraUsuarioAnexo"
  )
);

// Transferencias Interbancarias
const LbtrLocales = lazy(() =>
  import("@pages/TransferInterbancarias/LBTR-Locales")
);
const LbtrRegionales = lazy(() =>
  import("@pages/TransferInterbancarias/LBTR-Regionales")
);

// Notificaciones
const Notifications = lazy(() => import("@pages/Notifications/notifications"));
const DetailsNotification = lazy(() =>
  import("@pages/Notifications/DetailsNotification")
);

// Colectores genericos
const ColectoresGenericos = lazy(() =>
  import("@pages/Colectores/ColectoresGenericos")
);

const SolicitudTerceros = lazy(() =>
  import("@pages/administracion/SolicitudTerceros/solicitudTeceros")
);
const DetalleSolicitudTercero = lazy(() =>
  import("@pages/administracion/SolicitudTerceros/detalleSolicitudTercero")
);

const CambioClave = lazy(() => import("@pages/Session/CambioClave"));

const Home = lazy(() => import("@pages/Home"));
const Bienvenida = lazy(() => import("@pages/Bienvenida"));
const ConsejosSeguridad = lazy(() => import("@pages/ConsejosSeguridad"));
const Error403 = lazy(() => import("@pages/Error/error403"));

// ==> Transfer365
const Transfer365 = lazy(() => import("@pages/Transfer365/transfer365"));
const Transfer365Masivo = lazy(() =>
  import("@pages/Transfer365/Transfer365Masivo")
);
const Transfer365Business = lazy(() =>
  import("@pages/Transfer365Business/transfer365Business")
);
const Transfer365CtaHabientes = lazy(() =>
  import("@pages/Transfer365CtaHabientes/Transfer365CtaHabientes")
);
const Transfer365ObligacionesBCR = lazy(() =>
  import("@pages/Transfer365ObligacionesBCR/Transfer365ObligacionesBCR")
);

const Transfer365Movil = lazy(() =>
  import("@pages/Transfer365Movil/Transfer365Movil")
);

// ==> Cuentas Frecuentes Individual
const CuentasFrecuentes365Individual = lazy(() =>
  import("@pages/CuentasFrecuentes/CuentasFrecuentesT365Individual")
);

// ==> Cuentas Frecuentes Movil
const CuentasFrecuentesT365Movil = lazy(() =>
  import("@pages/CuentasFrecuentes/CuentasFrecuentesT365Movil")
);

// ==> Cuentas Solicitudes pendientes
const CuentasFrecuentesT365SoliPendientes = lazy(() =>
  import("@pages/CuentasFrecuentes/CuentasFrecuentesT365SoliPendientes")
);

// ==> Mis cuentas favoritas agregadas
const CuentasFrecuentesT365MisCuentas = lazy(() =>
  import("@pages/CuentasFrecuentes/CuentasFrecuentesT365MisCuentas")
);
// ==> Cuentas Transfer365 Recurrentes
const CuentasRecurrentes = lazy(() =>
  import("@pages/CuentasRecurrentes/CuentasRecurrentes")
);

// ==> Cuentas Transfer365 Recurrentes
const DetalleCuentasRecurrentes = lazy(() =>
  import("@pages/CuentasRecurrentes/detalleCuentasRecurrentes")
);

// ==> Salud para todos
const SaludParaTodos = lazy(() => import("@pages/SaludParaTodos/index"));

// ==> Cambio de PIN ATM
const CambioPinATM = lazy(() => import("@pages/CambioPinATM/cambioPinATM"));

// ==> Planillas Procesadas
const PlanillasProcesadas = lazy(() =>
  import("@pages/PlanillasProcesadas/PlanillasProcesadas")
);
const Transfer365MasivasProcesadas = lazy(() =>
  import("@pages/PlanillasProcesadas/Transfer365MasivasProcesadas")
);

// ==> Sol. Actualización de datos
const SolActualizacionDatos = lazy(() =>
  import("@pages/SolActualizacionDatos")
);

const defaultHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const App = () => {
  // ==> Contexts
  const { state: store } = useSessionContext();

  const { logout } = useLogout();

  axios.interceptors.response.use(
    async (config) => {
      return config;
    },
    async (err) => {
      if (err.toString().includes(401)) {
        logout();
      }
    }
  );

  useEffect(() => {
    const token = store?.session?.token || "";
    setGlobals({
      headers: {
        ...defaultHeaders,
        ...(token && { accessToken: token }),
      },
    });
  }, []);

  const token = store?.session?.token || "";
  if (token) {
    // ==> Agregar "accessToken" en cabeceras de Peticiones
    axios.defaults.headers.common["accessToken"] = token;
  }
  // ==> Agregar "language" en cabeceras de Peticiones
  axios.defaults.headers.common["language"] = store?.language || "es";

  const preFix = window.location.hostname === "localhost" ? "" : "/banca-bfa";

  return (
    <Suspense fallback={<Loading />}>
      <Router basename={preFix}>
        <LogoutHook />
        <ExtendSession />
        <Switch>
          <PrivateRouter exact path="/" isLogin component={Login} />
          <PrivateRouter exact path="/Home" component={Home} />
          <PrivateRouter exact path="/Bienvenida" component={Bienvenida} />
          <PrivateRouter
            exact
            path="/ConsejosSeguridad"
            component={ConsejosSeguridad}
          />
          <PrivateRouter
            exact
            path="/TransferenciasEntreCuentas"
            component={TransferenciaCtaPropias}
          />
          <PrivateRouter
            exact
            path="/TransferenciasEntreCuentasTerceros"
            component={TransferenciaCtaTerceros}
          />
          <PrivateRouter
            exact
            path="/AprobacionesPendientes"
            component={AprobacionesPendientes}
          />
          <PrivateRouter
            exact
            type="public"
            path="/CambioClave"
            component={CambioClave}
          />

          {/* Cuentas Corriente */}
          <PrivateRouter
            exact
            path="/ConsultaCuentaCorriente"
            component={ConsultaCuentaCorriente}
          />
          <PrivateRouter
            exact
            path="/DetalleCuentaCorriente"
            component={DetalleCuentaCorriente}
          />
          <PrivateRouter
            exact
            path="/MovimientosCuentaCorriente"
            component={MovimientosCuentaCorriente}
          />
          <PrivateRouter
            exact
            path="/EstadoCuentaCorriente"
            component={EstadoCuentaCorriente}
          />

          {/* Cuentas Ahorro */}
          <PrivateRouter
            exact
            path="/ConsultaCuentasAhorro"
            component={ConsultaCuentasAhorro}
          />
          <PrivateRouter
            exact
            path="/DetalleCuentaAhorro"
            component={DetalleCuentaAhorro}
          />
          <PrivateRouter
            exact
            path="/MovimientosCuentaAhorro"
            component={MovimientosCuentaAhorro}
          />
          <PrivateRouter
            exact
            path="/EstadoCuentaAhorro"
            component={EstadoCuentaAhorro}
          />

          {/* Depositos a plazo */}
          <PrivateRouter
            exact
            path="/ConsultaDepositosPlazo"
            component={ConsultaDepositosPlazo}
          />
          <PrivateRouter
            exact
            path="/DetalleDepositosPlazo"
            component={DetalleDepositosPlazo}
          />

          <PrivateRouter
            exact
            path="/SolicitudChequera"
            component={SolicitudChequera}
          />
          <PrivateRouter
            exact
            path="/PreSolicitudCredito"
            component={PreSolicitudCredito}
          />
          <PrivateRouter
            exact
            path="/PreSolicitudSeguro"
            component={PreSolicitudSeguro}
          />
          <PrivateRouter
            exact
            path="/PreSolicitudSuspensionCheques"
            component={PreSolicitudSuspensionCheques}
          />
          <PrivateRouter
            exact
            path="/PreSolicitudCaptacion"
            component={PreSolicitudCaptacion}
          />
          <PrivateRouter
            exact
            path="/PagoPrestamoPropio"
            component={PagoPrestamoPropio}
          />

          {/* Prestamos */}
          <PrivateRouter
            exact
            path="/ConsultasSegurosDePrestamos"
            component={ConsultasSegurosDePrestamos}
          />
          <PrivateRouter
            exact
            path="/PagoPrestamosTerceros"
            component={PagoPrestamosTerceros}
          />
          <PrivateRouter
            exact
            path="/ConsultaPrestamos"
            component={ConsultaPrestamos}
          />
          <PrivateRouter
            exact
            path="/DetallePrestamo"
            component={DetallePrestamo}
          />

          {/* Planilla */}
          <PrivateRouter
            exact
            path="/CargaPlanilla"
            component={CargaPlanilla}
          />

          {/* Colectores */}
          <PrivateRouter
            exact
            path="/MinisterioHacienda"
            component={MinisterioHacienda}
          />
          <PrivateRouter exact path="/DELSUR" component={DELSUR} />
          <PrivateRouter exact path="/ISSS" component={Isss} />
          <PrivateRouter exact path="/CLARO" component={Claro} />
          <PrivateRouter exact path="/GrupoAES" component={GrupoAES} />
          <PrivateRouter exact path="/CNR" component={CNR} />
          <PrivateRouter exact path="/CIEX" component={CIEX} />
          <PrivateRouter exact path="/FSV" component={FSV} />
          <PrivateRouter exact path="/AFP-confia" component={AfpConfia} />
          <PrivateRouter exact path="/AFP-crecer" component={AfpCrecer} />
          <PrivateRouter exact path="/PuntoExpress" component={PuntoExpress} />
          <PrivateRouter
            exact
            path="/ColectoresGenericos"
            component={ColectoresGenericos}
          />

          <PrivateRouter
            exact
            path="/SolicitudTransferenciasInternacionales"
            component={SolicitudTransferenciasInternacionales}
          />
          <PrivateRouter
            exact
            path="/SolicitudChequesInternacionales"
            component={SolicitudChequesInternacionales}
          />
          <PrivateRouter
            exact
            path="/SolicitudChequeGerencia"
            component={SolicitudChequeGerencia}
          />
          <PrivateRouter
            exact
            path="/ReporteTarjetaDebitoViaje"
            component={ReporteTarjetaDebitoViaje}
          />

          {/* Administración */}
          <PrivateRouter exact path="/misFavoritos" component={Favoritos} />
          <PrivateRouter
            exact
            path="/detalleFavoritos"
            component={DetalleFavoritos}
          />
          <PrivateRouter
            exact
            path="/cargosAutomaticos"
            component={CargosAutomaticos}
          />
          <PrivateRouter
            exact
            path="/detalleCargosAutomaticos"
            component={DetalleCargosAutomaticos}
          />
          <PrivateRouter
            exact
            path="/usuariosAnexos"
            component={UsuariosAnexos}
          />
          <PrivateRouter
            exact
            path="/bitacoraUsuarioAnexo"
            component={BitacoraUsuarioAnexo}
          />
          <PrivateRouter
            exact
            path="/detalleBitacoraUsuarioAnexo"
            component={DetalleBitacoraUsuarioAnexo}
          />
          <PrivateRouter
            exact
            path="/detalleUsuariosAnexos"
            component={DetalleUsuariosAnexos}
          />

          <PrivateRouter exact path="/LbtrLocales" component={LbtrLocales} />
          <PrivateRouter
            exact
            path="/LbtrRegionales"
            component={LbtrRegionales}
          />

          <PrivateRouter
            exact
            path="/notifications"
            component={Notifications}
          />
          <PrivateRouter
            exact
            path="/DetailsNotification"
            component={DetailsNotification}
          />

          <PrivateRouter
            exact
            path="/solicitudTerceros"
            component={SolicitudTerceros}
          />
          <PrivateRouter
            exact
            path="/detalleSolicitudTercero"
            component={DetalleSolicitudTercero}
          />

          <PrivateRouter exact path="/Transfer365" component={Transfer365} />

          <PrivateRouter
            exact
            path="/Transfer365Business"
            component={Transfer365Business}
          />

          <PrivateRouter
            exact
            path="/Transfer365Masivo"
            component={Transfer365Masivo}
          />

          <PrivateRouter
            exact
            path="/Transfer365CtaHabientes"
            component={Transfer365CtaHabientes}
          />

          <PrivateRouter
            exact
            path="/Transfer365ObligacionesBCR"
            component={Transfer365ObligacionesBCR}
          />

          <PrivateRouter
            exact
            path="/Transfer365Movil"
            component={Transfer365Movil}
          />

          <PrivateRouter
            exact
            path="/SaludParaTodos"
            component={SaludParaTodos}
          />

          <PrivateRouter exact path="/CambioPinATM" component={CambioPinATM} />

          <PrivateRouter
            exact
            path="/PlanillasProcesadas"
            component={PlanillasProcesadas}
          />

          <PrivateRouter
            exact
            path="/Transfer365MasivasProcesadas"
            component={Transfer365MasivasProcesadas}
          />

          <PrivateRouter
            exact
            path="/SolActualizacionDatos"
            component={SolActualizacionDatos}
          />

          <PrivateRouter exact path="/login" isLogin component={Login} />

          <PrivateRouter
            exact
            path="/CuentasFrecuentesT365"
            component={CuentasFrecuentes365Individual}
          />

          <PrivateRouter
            exact
            path="/T365MovilCF"
            component={CuentasFrecuentesT365Movil}
          />

          <PrivateRouter
            exact
            path="/T365SolPendientes"
            component={CuentasFrecuentesT365SoliPendientes}
          />

          <PrivateRouter
            exact
            path="/T365MisCuentas"
            component={CuentasFrecuentesT365MisCuentas}
          />
          <PrivateRouter
            exact
            path="/T365SolRecurrentes"
            component={CuentasRecurrentes}
          />

          <PrivateRouter
            exact
            path="/detalleCuentasRecurrentes"
            component={DetalleCuentasRecurrentes}
          />

          <PrivateRouter exact path="*" component={Error403} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
