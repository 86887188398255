import React, { createContext, useReducer, useEffect } from "react";

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { ConfigProvider as ConfigProviderAntd } from "antd";

// Reducer
import configReducer, { STORAGE_KEY, initialState } from "@reducers/configReducer";

// Hook
import SecurityStorage from "@/hooks/SecurityStorage";

const defaultLng = window.navigator.language.split("-")[0];

const lngInitConfig = {
    interpolation: { escapeValue: false },
    lng: JSON.parse(SecurityStorage.getItem(STORAGE_KEY))?.language ?? defaultLng ?? "es",
};

i18next.init(lngInitConfig);

export const ConfigContext = createContext({ state: initialState, dispatch: () => { } });

/**
 * Provider for the config context.
 *
 * @param {Object} props - The props for the provider.
 * @param {Object} props.children - The children components to be rendered within the provider.
 * @returns {JSX.Element} The config context provider component.
 */
export const ConfigProvider = ({ children }) => {

    const [state, dispatch] = useReducer(configReducer, initialState);

    useEffect(() => {
        restoreState();
    }, []);

    // window.addEventListener("storage", (ev) => {
    //     if (ev.key === STORAGE_KEY) {
    //         restoreState();
    //     }
    // })

    const restoreState = () => {
        const config = SecurityStorage.getItem(STORAGE_KEY);

        if (config) {
            // Comparar config con state
            if (state && JSON.stringify(state) === config) return;

            // Restore state
            const body = JSON.parse(config) ?? {};
            dispatch({
                type: "RESTORE",
                payload: body,
            });
        } else getDeviceConfig();
    }

    const getDeviceConfig = async () => {
        dispatch({
            type: "DEFAULT",
            payload: {
                language: defaultLng,
                theme: "light",
            },
        });
        await getInfoDevice();
    };

    const getInfoDevice = async () => {
        const ip = await fetch("https://api.ipify.org?format=json");
        const { ip: ipDevice } = await ip.json();

        dispatch({
            type: "SET_DEVICE",
            payload: {
                ip: ipDevice,
            },
        });
    }

    return (
        <I18nextProvider i18n={i18next}>
            <ConfigProviderAntd>
                <ConfigContext.Provider value={{ state, dispatch }}>
                    {children}
                </ConfigContext.Provider>
            </ConfigProviderAntd>
        </I18nextProvider>
    );
}