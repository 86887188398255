import React from "react";

// ==> Contexts
import { ConfigProvider } from "@providers/ConfigProvider";
import { SessionProvider } from "@providers/SessionProvider";
import { CacheProvider } from "@providers/CacheProvider";

/**
 * Main provider for the application.
 *
 * @param {Object} props - The props for the provider.
 * @param {JSX.Element | JSX.Element[]} props.children - The children components to be rendered within the provider.
 * @returns {JSX.Element} The main provider component.
 */
export const MainProvider = ({ children }) => (
    <CacheProvider>
        <ConfigProvider>
            <SessionProvider>{children}</SessionProvider>
        </ConfigProvider>
    </CacheProvider>
);
