import i18n from "i18next";

// ==> Helpers
import { postMessage } from "@helpers/sw";

// Hook
import SecurityStorage from "@/hooks/SecurityStorage";

const initialState = {
  user: {
    code: "",
    type: "",
    role: "",
    typePerson: "",
  },
  session: {
    id: "",
    token: "",
    rToken: "",
    time: "",
    loginTime: "",
    logoutTime: "",
    changePass: false,
  },
  isLogged: false,
};

const STORAGE_KEY = "BFASESSION";

/**
 * Reducer for handling session state changes.
 *
 * @param {Object} state - The current state of the session.
 * @param {Object} action - The action to be performed.
 * @param {string} action.type - The type of the action.
 * @param {Object} action.payload - The payload of the action.
 * @returns {Object} The new state of the session.
 */
const sessionReducer = (state = initialState, action) => {

  let body = {};

  switch (action?.type) {
    case "SET_SESION":
      body = {
        ...state,
        session: action?.payload?.session,
        user: action?.payload?.user,
        isLogged: true,
      };

      postMessage({ action: "login", data: { msg: i18n.t("login-exito") } });
      SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(body));
      return body;
    case "UPDATE_SESION":
      body = {
        ...state,
        session: {
          ...(state.session || {}),
          ...(action?.payload?.session || {}),
        },
        user: {
          ...(state.user || {}),
          ...(action?.payload?.user || {}),
        },
        isLogged: true,
      };

      SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(body));
      return body;
    case "RESTORE":
      body = {
        ...state,
        session: action?.payload?.session,
        user: action?.payload?.user,
        isLogged: action?.payload?.isLogged || false,
      };

      SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(body));
      return body;
    case "SET_CHANGE_PASS":
      body = {
        ...state,
        session: {
          ...(action?.payload?.session || {}),
          changePass: true,
        },
        user: {
          ...(action?.payload?.user || {}),
        },
      };

      SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(body));
      return body;
    case "KILL_SESION":

      body = {
        ...initialState,
        isLogged: false,
      };

      const msg = action?.payload?.msg || i18n.t("cerrarSession-msg");
      postMessage({ action: "logout", data: { msg: msg } });

      SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(body));
      return body;
    case "DEFAULT":
      body = initialState;

      SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(body));
      return body;
    default:
      return state;
  }
};

export { STORAGE_KEY, initialState }
export default sessionReducer;
