import React, { lazy, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* ==> Context <== *//
import useSessionContext from "@contexts/useSessionContext"

// * ==> Helper
import { changeContentInputError } from "@/helpers/helpers";

//* ==> Components <== *//
const Login = lazy(() => import("@pages/Login"));
const CambioClave = lazy(() => import("@pages/Session/CambioClave"));
// const Error403 = lazy(() => import("@pages/Error/error403"));

const PrivateRouter = ({
  type = "private",
  path,
  isLogin,
  component,
  ...rest
}) => {
  // ==> Contexts
  const { state: store } = useSessionContext();

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("bfaEnLinea-title");
    changeContentInputError(t("infoObligatoria-label"));
  }, []);

  // ==> Cambio de contraseña obligatorio
  if (store?.session?.changePass) return <CambioClave />;
  else if (type === "private") {
    // Si están intentando acceder al login y está logueado, redireccionar a Home
    if (isLogin && store?.isLogged) return <Redirect to="Home" />;

    // Si no está logueado, redireccionar al login
    if (!store?.isLogged) return <Login />;

    return (
      <Route
        {...rest}
        path={path}
        component={component}
        key={Math.random()}
      />
    );
  } else if (type === "public") {
    return (
      <Route {...rest} path={path} component={component} key={Math.random()} />
    );
  }
};

export default PrivateRouter;
