import moment from "moment";
import i18n from "i18next";

// ==> Locale datepicker
import esES from "antd/es/date-picker/locale/es_ES";
import enUS from "antd/es/date-picker/locale/en_US";

// ==> Helpers
import { maskDUI, maskNIT, maskTelefono } from "./customMask";

// ==> Env
import { DATE_ES, DATE_EN, FULL_DATE_ES, FULL_DATE_EN } from "@config/env";

/**
 * Author: Francisco Edgardo Hernández Palacios
 * Description: File to save functions that can be reused
 */

const currencyFormat = (num) => {
  return (
    "$" +
    Number(num)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};

const validEmail = (email) => {
  const valid = new RegExp(/\S+@\S+\.\S+/);

  if (valid.test(email)) return true;
  else return false;
};

const onlyFloat = (e, setState) => {
  const value = e?.target?.value;
  const valid = new RegExp(/^\d*\.?\d{0,2}$/g);

  if (valid.test(value)) {
    const monto = value.charAt(0) === "." ? "0" + value : value;

    // ==> Validar que el monto no tenga más de 9 digitos
    if ((value || "").length > 9 && (value || "").indexOf(".") === -1) return;

    setState(monto);
  }
};

const onlyFloatWithParams = (e, setState, params) => {
  // ==> Params
  const decim = params?.decim || 2;
  const length = params?.max || "";
  const max = length ? length - (decim + 1) : 9;

  const value = e?.target?.value;
  const valid = new RegExp(/^\d*\.?\d{0,2}$/g);

  if (valid.test(value)) {
    const monto = value.charAt(0) === "." ? "0" + value : value;

    // ==> Validar que el monto no tenga más de {max} digitos
    if ((value || "").length > max && (value || "").indexOf(".") === -1) return;

    setState(monto);
  }
};

const onlyNumber = (e, setState) => {
  const value = e?.target?.value;
  const valid = new RegExp(/^\d*$/g);

  if (valid.test(value)) setState(value);
};

const onlyText = (e, setState) => {
  const value = e?.target?.value;
  const valid = new RegExp(/^[A-zÀ-ú\s]*$/g);

  if (valid.test(value)) setState(value);
};

const onlyTextNoAccents = (e, setState) => {
  const value = e?.target?.value;
  const valid = new RegExp(/^[a-zA-Z\s]*$/g);

  if (valid.test(value)) {
    const text = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    setState(text);
  }
};

const alphanumNoCharSpecial = (e, setState) => {
  // ==> Alphanumeric characters allowed without allowing special characters
  const value = e?.target?.value;
  const valid = new RegExp(/^[a-zA-Z0-9\s]*$/g);

  if (valid.test(value)) {
    // ==> No blanks space
    const text = value.replace(/\s/g, "");
    setState(text);
  }
};

const deleteMask = (value = "") => {
  const valid =
    (value &&
      value.toString().replace(/-/g, "").replace(/_/g, "").replace(/ /g, "")) ||
    "";

  return valid;
};

const decimalFormat = (num) => {
  if (!num) return "0.00";

  const monto = Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  return monto === "NaN" ? "0.00" : monto;
};

const formatAccount = (value) => {
  const valid = value.replace(/\D/g, "");
  const num = valid.replace(/^(\d{3})(\d{3})(\d{6})(\d{1})$/, "$1-$2-$3-$4");
  return num;
};

/**
 * Agregar Número de Cuenta con mask
 * y monto disponible
 * @param {*} item
 * @returns
 */
const formatNameAccount = (item, options = { showType: true, showTitular: false }) => {

  const { showType = true, showTitular = false } = options;

  if (!item) return "";
  else {
    const cta = item?.cuenta || "";
    const saldoDisponible = item?.saldoDisponible || "";
    const tipoCta = item?.producto || "";
    const titular = (item?.titular || "").toString().replace(/,/g, "");

    const typeCta =
      tipoCta >= 100 && tipoCta < 200
        ? i18n.t("ctaCorriente-type")
        : i18n.t("ctaAhorro-type");

    const numCta = cta ? formatAccount(cta) : "";
    const monto = saldoDisponible ? currencyFormat(saldoDisponible) : "";

    let complemt = "";
    if (showType) complemt = typeCta;
    if (showTitular) complemt = complemt ? `${complemt}, ${titular}` : titular;
    else if (monto) complemt = complemt ? `${complemt}, ${monto}` : monto;

    const name = complemt ? `${numCta} (${complemt})` : numCta;
    return name;
  }
};

const validAvailableBalance = (cuentas = [], select, monto) => {
  // ==> Buscar cuenta seleccionada y obtener saldo disponible
  const saldo =
    (cuentas || [])?.find((el) => el.cuenta === select)?.saldoDisponible || "";

  // ==> Validar que el monto no sea mayor al saldo disponible
  if (monto > saldo) return false;
  else return true;
};

const changeContentInputError = (label) => {
  const msg = label || "🛈 Información Obligatoria";

  document.styleSheets[0].addRule(
    ".errorInput::after",
    `content: "${msg}" !important;`
  );
};

const formatDocument = (val, type) => {
  if (!val) return val;
  else {
    const value = deleteMask(val || ""); // ==> Eliminar mask a valor
    let mask = "";

    switch (type) {
      case "DUI":
        mask = deleteMask(maskDUI); // ==> Mask para DUI
        if ((value || "").length === (mask || "").length) {
          const num = value.replace(/^(\d{8})(\d{1})$/, "$1-$2");
          return num;
        } else return val;
        break;
      case "NIT":
        mask = deleteMask(maskNIT); // ==> Mask para NIT
        if ((value || "").length === (mask || "").length) {
          const num = value.replace(
            /^(\d{4})(\d{6})(\d{3})(\d{1})$/,
            "$1-$2-$3-$4"
          );
          return num;
        } else return val;
      default:
        return val;
        break;
    }
  }
};

const validUser = (user = {}) => {
  // ==> Results
  const NO_ACCESS = "NO_ACCESS";
  const SHOW_SIGNATURE = "SHOW_SIGNATURE";
  const GO_CHECK = "GO_CHECK";

  // ==> Valid user info
  if (!Object.keys(user).length) return NO_ACCESS;

  // ==> Info user
  const role = user?.role || "";
  const type = user?.typePerson || "";

  if (!role || !type) return NO_ACCESS;

  if (type === "N" && role === "N") return GO_CHECK;
  else if (type === "J" || type === "P") {
    switch (role) {
      case "P":
      case "PT":
        return SHOW_SIGNATURE;

      case "N":
        return NO_ACCESS;

      case "T":
        return NO_ACCESS;

      default:
        return NO_ACCESS;
    }
  } else return NO_ACCESS;
};

/**
 * Change of date format, depending on the language
 * @param {*} date
 * @param {*} format
 * @param {*} strDate
 * @returns
 */
const formatDate = (date, format, hours = true) => {
  // ==> Valid data
  if (!date || !format) return "";

  return moment(date, format).format(getFormatDate(hours));
};

const getFormatDate = (hours = false) => {
  // ==> Languague
  const lng = i18n.language || "es";

  return lng === "en"
    ? hours
      ? FULL_DATE_EN
      : DATE_EN
    : hours
      ? FULL_DATE_ES
      : DATE_ES;
};

const getParsedDate = (strDate) =>{
  var strSplitDate = String(strDate).split(' ');
  var date = new Date(strSplitDate[0]);
  // alert(date);
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
      dd = '0' + dd;
  }
  if (mm < 10) {
      mm = '0' + mm;
  }
  date =  dd + "/" + mm + "/" + yyyy;
  return date.toString();
};

const getLngPicker = () => {
  // ==> Languague
  const lng = i18n.language || "es";
  return lng === "en" ? enUS : esES;
};

const textSentenceType = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const validMaskPhone = (tel) => {
  if (!tel) return false;

  const length = (deleteMask(tel || "") || "").length;
  const mask = (deleteMask(maskTelefono) || "").length;

  return length === mask;
};

const validCel = (cel) => {
  if (!cel) return false;

  // ==> Valid mask
  if (!validMaskPhone(cel)) return false;

  // ==> Valid inital numbers
  return (cel || "").charAt(0) === "6" || (cel || "").charAt(0) === "7";
};

export {
  currencyFormat,
  validEmail,
  onlyFloat,
  onlyFloatWithParams,
  onlyNumber,
  onlyText,
  onlyTextNoAccents,
  alphanumNoCharSpecial,
  deleteMask,
  decimalFormat,
  formatAccount,
  formatNameAccount,
  validAvailableBalance,
  changeContentInputError,
  formatDocument,
  validUser,
  formatDate,
  getFormatDate,
  getLngPicker,
  textSentenceType,
  validMaskPhone,
  validCel,
  getParsedDate,
};
