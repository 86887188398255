const initialState = {
    isSuccess: false,
    isLoading: false,
    isError: false,
    data: null,
    error: null,
};

/**
 * Reducer for handling api state changes.
 *
 * @param {Object} state - The current state of the api.
 * @param {Object} action - The action to be performed.
 * @param {string} action.type - The type of the action.
 * @param {Object} action.payload - The payload of the action.
 * @returns {Object} The new state of the api.
 */
const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_INIT":
            return {
                ...state,
                status: 0,
                isSuccess: false,
                isLoading: true,
                isError: false,
                error: null,
                data: null,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                status: action.payload.status,
                isSuccess: Math.random(),
                isLoading: false,
                isError: false,
                data: action.payload.data,
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                status: action.payload.status,
                isSuccess: false,
                isLoading: false,
                isError: true,
                error: action.payload.data,
            };
        default:
            throw new Error();
    }
};

export { initialState };
export default apiReducer;