const initialState = {
};

const cacheReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CACHE":
            return {
                ...state,
                [action.payload.key]: action.payload.data,
            };
        case "REMOVE_CACHE":
            return {
                ...state,
                [action.payload.key]: null,
            };
        default:
            throw new Error();
    }
}

export { initialState };
export default cacheReducer;