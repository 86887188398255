import React from "react";
import ReactDOM from "react-dom";

import App from "@/App";
import "@config/i18n";
import "@config/configuredSW";

// ==> Context
import { MainProvider } from "@providers/MainProvider";

import "moment/locale/es";
import "moment/locale/en-gb";

ReactDOM.render(
  <MainProvider>
    <App />
  </MainProvider>,
  document.getElementById("root")
);
