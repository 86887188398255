/**
 * Post a message to the service worker.
 *
 * @param {Object} message - The message to be posted.
 * @param {string} message.action - The action to be performed.
 * @param {Object} [message.data] - The data to be sent with the action.
 */
export const postMessage = ({ action, data }) => {
    const controller = navigator.serviceWorker?.controller;

    if ("serviceWorker" in navigator && controller) {
        controller.postMessage({
            action: action,
            data: { ...data ?? {} }
        });
    }
};