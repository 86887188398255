import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

//* ==> Paths <== *//
import { pathCacheTranslate } from "@config/paths";

const success = "./icons/BFAonline_ventana_emerg_aceptacion.png";
const warning = "./icons/BFAonline_ventana_emerg_advertencia.png";
const error = "./icons/BFAonline_ventana_emerg_negacion.png";
const question = "./icons/BFAonline_ventana_emerg_consulta.png";

/**
 * Componente para Desplegar mensajes de Alerta personalizados
 * @typedef CustomAlertProps
 * @property {string} text Texto a desplegar
 * @property {'success' | 'error' | 'question' | 'warning'} [type] Tipo de alerta
 * @property {boolean} isTrad Verificar si ya fue traducido
 */

/**
 * @author Francisco Hernández
 * @param {CustomAlertProps} props
 * @returns
 */

const CustomAlert = (props) => {
  const { t, i18n } = useTranslation();

  // ==> Props
  const { text, type = "warning", isTrad = true } = props;

  //* ==> STATE <== *//
  const [lng] = useState(i18n?.language || "");
  const [loading, setLoading] = useState(false);

  // ==> Mensaje traducido
  const [mensaje, setMensaje] = useState("");

  const translater = async () => {
    setLoading(true); // ==> Loading

    const body = {
      url: "http://",
      mensaje: {
        codigo: 0,
        descripcion: clearText(),
      },
      lenguaje: lng || "es",
    };

    await fetch(pathCacheTranslate, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ ...body }),
    })
      .then((response) => {
        response
          .json()
          .then((json) => {
            setMensaje(json?.mensaje?.descripcion || "");
          })
          .catch((e) => console.error("||* ==> Error requestApi <== *||", e));
      })
      .catch((e) => console.error("||* ==> Error requestApi <== *||", e));

    setLoading(false); // ==> Loading
  };

  const clearText = () => {
    // ==> Replace
    const msg = (text || "").replace("Error inesperado:", "") || "";
    return msg;
  };

  useEffect(() => {
    if (!isTrad && lng !== "es") translater();
  }, [isTrad, lng]);

  const getIcon = () => {
    switch (type) {
      case "success":
        return success;
      case "warning":
        return warning;
      case "question":
        return question;
      case "error":
        return error;
      default:
        console.error("Error: type not found");
        return error;
    }
  };

  const getText = () => {
    if (isTrad || lng === "es") return clearText();
    return mensaje || clearText() || "";
  };

  return (
    <Wrapper>
      <img src={getIcon()} />
      {loading ? (
        <div className="lds-dual-ring"></div>
      ) : (
        <p className="textAlert">{getText()} </p>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 60px;
    margin-top: 20px;
  }

  p {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .lds-dual-ring {
    margin-top: 30px;
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    /* margin: 8px; */
    border-radius: 50%;
    border: 2px solid var(--color-secundary-gray);
    border-color: var(--color-secundary-gray) transparent
      var(--color-secundary-gray) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default CustomAlert;
