import i18n from "i18next";
import moment from "moment";

//* ==> Helpers <== *//
import { changeContentInputError } from "@helpers/helpers";

// Hook
import SecurityStorage from "@/hooks/SecurityStorage";

const lngOpts = {
    en: "en",
    es: "es",
};

const initialState = {
    language: "es",
    theme: "light",
    device: {
        ip: ""
    }
};

const STORAGE_KEY = "CONFIG";

/**
 * Change the language of the application.
 *
 * @param {string} lng - The new language to be set.
 */
const changeLng = (lng = "es") => {
    const language = lngOpts?.[lng] ? lng : "es";

    i18n.changeLanguage(language);
    moment.locale(language);
    document.title = i18n.t("bfaEnLinea-title");
    changeContentInputError(i18n.t("infoObligatoria-label"));
}

/**
 * Reducer for handling configuration changes.
 *
 * @param {Object} state - The current state of the configuration.
 * @param {Object} action - The action to be performed.
 * @param {string} action.type - The type of the action.
 * @param {string} action.payload - The payload of the action.
 * @returns {Object} The new state of the configuration.
 */
const configReducer = (state = initialState, action) => {
    let config = {};

    switch (action?.type) {
        case "SET_LANGUAGE":
            config = {
                ...state,
                language: action.payload,
            };

            changeLng(action.payload);
            SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(config));
            return config;
        case "SET_THEME":
            config = {
                ...state,
                theme: action.payload,
            };

            SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(config));
            return config;

        case "RESTORE":
            config = {
                ...state,
                ...action.payload,
            };

            changeLng(action.payload?.language);
            SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(config));
            return config;

        case "DEFAULT":
            config = initialState;

            SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(config));
            changeLng();
            return config;

        case "SET_DEVICE":
            config = {
                ...state,
                device: action.payload,
            };

            SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(config));
            return config;

        case "CLEAR_DEVICE":
            config = {
                ...state,
                device: initialState.device,
            };

            SecurityStorage.setItem(STORAGE_KEY, JSON.stringify(config));
            return config;

        default:
            return state;
    }
};

export { STORAGE_KEY, initialState }
export default configReducer;