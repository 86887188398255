import { notification } from "antd";

const alertNotification = (msm = "", description = "", type = "error") => {
  const defaultDescrip =
    "An error has arisen, we are working to fix it. Please try again later.";
  const content = description ? description : defaultDescrip;
  notification[type]({
    message: msm,
    description: content,
    duration: 5,
    top: 40,
  });
};

export { alertNotification };
