import React from "react";
import styled from "styled-components";
import i18n from "i18next";

const Loading = () => {
  return (
    <Wrapper>
      <img
        src={i18n.language === "en" ? "loading-en.gif" : "loading.gif"}
        alt="loading"
        className="imgLoading"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fbfbfb;

  .imgLoading {
    width: 35%;

    @media (max-width: 768px) {
      width: 40%;
    }
  }
`;

export default Loading;
