import React, { createContext, useReducer } from "react";

//* ==> Reducers 
import cacheReducer, { initialState } from "@/utils/reducers/cacheReducer";

export const CacheContext = createContext({ state: initialState, dispatch: () => { } });

export const CacheProvider = ({ children }) => {

    const [state, dispatch] = useReducer(cacheReducer, initialState);

    return (
        <CacheContext.Provider value={{ state, dispatch }}>
            {children}
        </CacheContext.Provider>
    );
}